export const AccountMenuList = () => import('../../components/account/MenuList.vue' /* webpackChunkName: "components/account-menu-list" */).then(c => wrapFunctional(c.default || c))
export const AppBackdropOverlay = () => import('../../components/app/BackdropOverlay.vue' /* webpackChunkName: "components/app-backdrop-overlay" */).then(c => wrapFunctional(c.default || c))
export const AppBreadcrumbs = () => import('../../components/app/Breadcrumbs.vue' /* webpackChunkName: "components/app-breadcrumbs" */).then(c => wrapFunctional(c.default || c))
export const AppCookiePopup = () => import('../../components/app/CookiePopup.vue' /* webpackChunkName: "components/app-cookie-popup" */).then(c => wrapFunctional(c.default || c))
export const AppLangSwitcher = () => import('../../components/app/LangSwitcher.vue' /* webpackChunkName: "components/app-lang-switcher" */).then(c => wrapFunctional(c.default || c))
export const AppLayout = () => import('../../components/app/Layout.vue' /* webpackChunkName: "components/app-layout" */).then(c => wrapFunctional(c.default || c))
export const AppLazyHydrate = () => import('../../components/app/LazyHydrate.vue' /* webpackChunkName: "components/app-lazy-hydrate" */).then(c => wrapFunctional(c.default || c))
export const AppLoadingBar = () => import('../../components/app/LoadingBar.vue' /* webpackChunkName: "components/app-loading-bar" */).then(c => wrapFunctional(c.default || c))
export const AppModal = () => import('../../components/app/Modal.vue' /* webpackChunkName: "components/app-modal" */).then(c => wrapFunctional(c.default || c))
export const AppNoty = () => import('../../components/app/Noty.vue' /* webpackChunkName: "components/app-noty" */).then(c => wrapFunctional(c.default || c))
export const AppPageHeader = () => import('../../components/app/PageHeader.vue' /* webpackChunkName: "components/app-page-header" */).then(c => wrapFunctional(c.default || c))
export const AppSidebar = () => import('../../components/app/Sidebar.vue' /* webpackChunkName: "components/app-sidebar" */).then(c => wrapFunctional(c.default || c))
export const AppUSP = () => import('../../components/app/USP.vue' /* webpackChunkName: "components/app-u-s-p" */).then(c => wrapFunctional(c.default || c))
export const BaseBackdropOverlay = () => import('../../components/base/BackdropOverlay.vue' /* webpackChunkName: "components/base-backdrop-overlay" */).then(c => wrapFunctional(c.default || c))
export const BaseButton = () => import('../../components/base/Button.vue' /* webpackChunkName: "components/base-button" */).then(c => wrapFunctional(c.default || c))
export const BaseCheckbox = () => import('../../components/base/Checkbox.vue' /* webpackChunkName: "components/base-checkbox" */).then(c => wrapFunctional(c.default || c))
export const BaseCollapse = () => import('../../components/base/Collapse.vue' /* webpackChunkName: "components/base-collapse" */).then(c => wrapFunctional(c.default || c))
export const BaseCollapseItem = () => import('../../components/base/CollapseItem.vue' /* webpackChunkName: "components/base-collapse-item" */).then(c => wrapFunctional(c.default || c))
export const BaseCollapseOptions = () => import('../../components/base/CollapseOptions.vue' /* webpackChunkName: "components/base-collapse-options" */).then(c => wrapFunctional(c.default || c))
export const BaseCollapseTransition = () => import('../../components/base/CollapseTransition.vue' /* webpackChunkName: "components/base-collapse-transition" */).then(c => wrapFunctional(c.default || c))
export const BaseForm = () => import('../../components/base/Form.vue' /* webpackChunkName: "components/base-form" */).then(c => wrapFunctional(c.default || c))
export const BaseFormItem = () => import('../../components/base/FormItem.vue' /* webpackChunkName: "components/base-form-item" */).then(c => wrapFunctional(c.default || c))
export const BaseIcon = () => import('../../components/base/Icon.vue' /* webpackChunkName: "components/base-icon" */).then(c => wrapFunctional(c.default || c))
export const BaseImageZoomer = () => import('../../components/base/ImageZoomer.vue' /* webpackChunkName: "components/base-image-zoomer" */).then(c => wrapFunctional(c.default || c))
export const BaseInfiniteLoading = () => import('../../components/base/InfiniteLoading.vue' /* webpackChunkName: "components/base-infinite-loading" */).then(c => wrapFunctional(c.default || c))
export const BaseInput = () => import('../../components/base/Input.vue' /* webpackChunkName: "components/base-input" */).then(c => wrapFunctional(c.default || c))
export const BaseInputNumber = () => import('../../components/base/InputNumber.vue' /* webpackChunkName: "components/base-input-number" */).then(c => wrapFunctional(c.default || c))
export const BaseLightBox = () => import('../../components/base/LightBox.vue' /* webpackChunkName: "components/base-light-box" */).then(c => wrapFunctional(c.default || c))
export const BaseMap = () => import('../../components/base/Map.vue' /* webpackChunkName: "components/base-map" */).then(c => wrapFunctional(c.default || c))
export const BaseModal = () => import('../../components/base/Modal.vue' /* webpackChunkName: "components/base-modal" */).then(c => wrapFunctional(c.default || c))
export const BaseModalLayout = () => import('../../components/base/ModalLayout.vue' /* webpackChunkName: "components/base-modal-layout" */).then(c => wrapFunctional(c.default || c))
export const BaseOverlay = () => import('../../components/base/Overlay.vue' /* webpackChunkName: "components/base-overlay" */).then(c => wrapFunctional(c.default || c))
export const BasePagination = () => import('../../components/base/Pagination.vue' /* webpackChunkName: "components/base-pagination" */).then(c => wrapFunctional(c.default || c))
export const BaseRadio = () => import('../../components/base/Radio.vue' /* webpackChunkName: "components/base-radio" */).then(c => wrapFunctional(c.default || c))
export const BaseRatingBar = () => import('../../components/base/RatingBar.vue' /* webpackChunkName: "components/base-rating-bar" */).then(c => wrapFunctional(c.default || c))
export const BaseRatingBarGroup = () => import('../../components/base/RatingBarGroup.vue' /* webpackChunkName: "components/base-rating-bar-group" */).then(c => wrapFunctional(c.default || c))
export const BaseSelect = () => import('../../components/base/Select.vue' /* webpackChunkName: "components/base-select" */).then(c => wrapFunctional(c.default || c))
export const BaseSidebarLayout = () => import('../../components/base/SidebarLayout.vue' /* webpackChunkName: "components/base-sidebar-layout" */).then(c => wrapFunctional(c.default || c))
export const BaseStarRating = () => import('../../components/base/StarRating.vue' /* webpackChunkName: "components/base-star-rating" */).then(c => wrapFunctional(c.default || c))
export const BaseTextarea = () => import('../../components/base/Textarea.vue' /* webpackChunkName: "components/base-textarea" */).then(c => wrapFunctional(c.default || c))
export const BaseToggle = () => import('../../components/base/Toggle.vue' /* webpackChunkName: "components/base-toggle" */).then(c => wrapFunctional(c.default || c))
export const BaseTouchScroll = () => import('../../components/base/TouchScroll.vue' /* webpackChunkName: "components/base-touch-scroll" */).then(c => wrapFunctional(c.default || c))
export const BaseTransitionOpacity = () => import('../../components/base/TransitionOpacity.vue' /* webpackChunkName: "components/base-transition-opacity" */).then(c => wrapFunctional(c.default || c))
export const BaseVideo = () => import('../../components/base/Video.vue' /* webpackChunkName: "components/base-video" */).then(c => wrapFunctional(c.default || c))
export const BlogArticle = () => import('../../components/blog/Article.vue' /* webpackChunkName: "components/blog-article" */).then(c => wrapFunctional(c.default || c))
export const BlogLayout = () => import('../../components/blog/Layout.vue' /* webpackChunkName: "components/blog-layout" */).then(c => wrapFunctional(c.default || c))
export const BlogNewsArticles = () => import('../../components/blog/NewsArticles.vue' /* webpackChunkName: "components/blog-news-articles" */).then(c => wrapFunctional(c.default || c))
export const BlogSideMenu = () => import('../../components/blog/SideMenu.vue' /* webpackChunkName: "components/blog-side-menu" */).then(c => wrapFunctional(c.default || c))
export const BlogTagArticles = () => import('../../components/blog/TagArticles.vue' /* webpackChunkName: "components/blog-tag-articles" */).then(c => wrapFunctional(c.default || c))
export const BrandImageItem = () => import('../../components/brand/ImageItem.vue' /* webpackChunkName: "components/brand-image-item" */).then(c => wrapFunctional(c.default || c))
export const ContactAdvice = () => import('../../components/contact/Advice.vue' /* webpackChunkName: "components/contact-advice" */).then(c => wrapFunctional(c.default || c))
export const ContactSocialLinks = () => import('../../components/contact/SocialLinks.vue' /* webpackChunkName: "components/contact-social-links" */).then(c => wrapFunctional(c.default || c))
export const ContentsAssembly = () => import('../../components/contents/Assembly.vue' /* webpackChunkName: "components/contents-assembly" */).then(c => wrapFunctional(c.default || c))
export const ContentsBlocks = () => import('../../components/contents/Blocks.vue' /* webpackChunkName: "components/contents-blocks" */).then(c => wrapFunctional(c.default || c))
export const FaqQuestion = () => import('../../components/faq/Question.vue' /* webpackChunkName: "components/faq-question" */).then(c => wrapFunctional(c.default || c))
export const FormAddress = () => import('../../components/form/Address.vue' /* webpackChunkName: "components/form-address" */).then(c => wrapFunctional(c.default || c))
export const FormForgotPassword = () => import('../../components/form/ForgotPassword.vue' /* webpackChunkName: "components/form-forgot-password" */).then(c => wrapFunctional(c.default || c))
export const FormLogin = () => import('../../components/form/Login.vue' /* webpackChunkName: "components/form-login" */).then(c => wrapFunctional(c.default || c))
export const FormRegister = () => import('../../components/form/Register.vue' /* webpackChunkName: "components/form-register" */).then(c => wrapFunctional(c.default || c))
export const FormResetPassword = () => import('../../components/form/ResetPassword.vue' /* webpackChunkName: "components/form-reset-password" */).then(c => wrapFunctional(c.default || c))
export const FormReview = () => import('../../components/form/Review.vue' /* webpackChunkName: "components/form-review" */).then(c => wrapFunctional(c.default || c))
export const ModalAddress = () => import('../../components/modal/Address.vue' /* webpackChunkName: "components/modal-address" */).then(c => wrapFunctional(c.default || c))
export const SkeletonBrandList = () => import('../../components/skeleton/BrandList.vue' /* webpackChunkName: "components/skeleton-brand-list" */).then(c => wrapFunctional(c.default || c))
export const WidgetsUSP = () => import('../../components/widgets/USP.vue' /* webpackChunkName: "components/widgets-u-s-p" */).then(c => wrapFunctional(c.default || c))
export const AppFooter = () => import('../../components/app/footer/index.vue' /* webpackChunkName: "components/app-footer" */).then(c => wrapFunctional(c.default || c))
export const AppHeaderMenuBar = () => import('../../components/app/header/MenuBar.vue' /* webpackChunkName: "components/app-header-menu-bar" */).then(c => wrapFunctional(c.default || c))
export const AppHeaderMenuBtn = () => import('../../components/app/header/MenuBtn.vue' /* webpackChunkName: "components/app-header-menu-btn" */).then(c => wrapFunctional(c.default || c))
export const AppHeaderNavDropdown = () => import('../../components/app/header/NavDropdown.vue' /* webpackChunkName: "components/app-header-nav-dropdown" */).then(c => wrapFunctional(c.default || c))
export const AppHeaderNavPopover = () => import('../../components/app/header/NavPopover.vue' /* webpackChunkName: "components/app-header-nav-popover" */).then(c => wrapFunctional(c.default || c))
export const AppHeaderQuickLinks = () => import('../../components/app/header/QuickLinks.vue' /* webpackChunkName: "components/app-header-quick-links" */).then(c => wrapFunctional(c.default || c))
export const AppHeaderUserDropdown = () => import('../../components/app/header/UserDropdown.vue' /* webpackChunkName: "components/app-header-user-dropdown" */).then(c => wrapFunctional(c.default || c))
export const AppHeader = () => import('../../components/app/header/index.vue' /* webpackChunkName: "components/app-header" */).then(c => wrapFunctional(c.default || c))
export const AppSidebarAccountMenu = () => import('../../components/app/sidebar/AccountMenu.vue' /* webpackChunkName: "components/app-sidebar-account-menu" */).then(c => wrapFunctional(c.default || c))
export const AppSidebarMainMenu = () => import('../../components/app/sidebar/MainMenu.vue' /* webpackChunkName: "components/app-sidebar-main-menu" */).then(c => wrapFunctional(c.default || c))
export const AppSidebarMenu = () => import('../../components/app/sidebar/Menu.vue' /* webpackChunkName: "components/app-sidebar-menu" */).then(c => wrapFunctional(c.default || c))
export const AppSidebarTopMenu = () => import('../../components/app/sidebar/TopMenu.vue' /* webpackChunkName: "components/app-sidebar-top-menu" */).then(c => wrapFunctional(c.default || c))
export const BaseRangeSliderButton = () => import('../../components/base/range-slider/Button.vue' /* webpackChunkName: "components/base-range-slider-button" */).then(c => wrapFunctional(c.default || c))
export const BaseRangeSliderInputNumber = () => import('../../components/base/range-slider/InputNumber.vue' /* webpackChunkName: "components/base-range-slider-input-number" */).then(c => wrapFunctional(c.default || c))
export const BaseRangeSlider = () => import('../../components/base/range-slider/index.vue' /* webpackChunkName: "components/base-range-slider" */).then(c => wrapFunctional(c.default || c))
export const BlocksContentCard1Col = () => import('../../components/blocks/content/Card1Col.vue' /* webpackChunkName: "components/blocks-content-card1-col" */).then(c => wrapFunctional(c.default || c))
export const BlocksContentCard1Col4Images = () => import('../../components/blocks/content/Card1Col4Images.vue' /* webpackChunkName: "components/blocks-content-card1-col4-images" */).then(c => wrapFunctional(c.default || c))
export const BlocksContentCard2Col = () => import('../../components/blocks/content/Card2Col.vue' /* webpackChunkName: "components/blocks-content-card2-col" */).then(c => wrapFunctional(c.default || c))
export const BlocksContentCard3Col = () => import('../../components/blocks/content/Card3Col.vue' /* webpackChunkName: "components/blocks-content-card3-col" */).then(c => wrapFunctional(c.default || c))
export const BlocksContentCard3Rows = () => import('../../components/blocks/content/Card3Rows.vue' /* webpackChunkName: "components/blocks-content-card3-rows" */).then(c => wrapFunctional(c.default || c))
export const BlocksContentCardFwImage50Text50 = () => import('../../components/blocks/content/CardFwImage50Text50.vue' /* webpackChunkName: "components/blocks-content-card-fw-image50-text50" */).then(c => wrapFunctional(c.default || c))
export const BlocksContentCardFwImage50Text50BgImage = () => import('../../components/blocks/content/CardFwImage50Text50BgImage.vue' /* webpackChunkName: "components/blocks-content-card-fw-image50-text50-bg-image" */).then(c => wrapFunctional(c.default || c))
export const BlocksContentCardFwImage50Text50Green = () => import('../../components/blocks/content/CardFwImage50Text50Green.vue' /* webpackChunkName: "components/blocks-content-card-fw-image50-text50-green" */).then(c => wrapFunctional(c.default || c))
export const BlocksContentCardFwImage50Text50wButtons = () => import('../../components/blocks/content/CardFwImage50Text50wButtons.vue' /* webpackChunkName: "components/blocks-content-card-fw-image50-text50w-buttons" */).then(c => wrapFunctional(c.default || c))
export const BlocksContentCardFwImage50TextList50 = () => import('../../components/blocks/content/CardFwImage50TextList50.vue' /* webpackChunkName: "components/blocks-content-card-fw-image50-text-list50" */).then(c => wrapFunctional(c.default || c))
export const BlocksContentCardFwImage50TextList50LightBlue = () => import('../../components/blocks/content/CardFwImage50TextList50LightBlue.vue' /* webpackChunkName: "components/blocks-content-card-fw-image50-text-list50-light-blue" */).then(c => wrapFunctional(c.default || c))
export const BlocksContentCardFwImage60Text40Red = () => import('../../components/blocks/content/CardFwImage60Text40Red.vue' /* webpackChunkName: "components/blocks-content-card-fw-image60-text40-red" */).then(c => wrapFunctional(c.default || c))
export const BlocksContentCardFwText50Image50 = () => import('../../components/blocks/content/CardFwText50Image50.vue' /* webpackChunkName: "components/blocks-content-card-fw-text50-image50" */).then(c => wrapFunctional(c.default || c))
export const BlocksContentCardFwText50Image50BgImage = () => import('../../components/blocks/content/CardFwText50Image50BgImage.vue' /* webpackChunkName: "components/blocks-content-card-fw-text50-image50-bg-image" */).then(c => wrapFunctional(c.default || c))
export const BlocksContentCardFwText50Image50Orange = () => import('../../components/blocks/content/CardFwText50Image50Orange.vue' /* webpackChunkName: "components/blocks-content-card-fw-text50-image50-orange" */).then(c => wrapFunctional(c.default || c))
export const BlocksContentCardFwText50Image50RedLightest = () => import('../../components/blocks/content/CardFwText50Image50RedLightest.vue' /* webpackChunkName: "components/blocks-content-card-fw-text50-image50-red-lightest" */).then(c => wrapFunctional(c.default || c))
export const BlocksContentCardFwTextList50Image50 = () => import('../../components/blocks/content/CardFwTextList50Image50.vue' /* webpackChunkName: "components/blocks-content-card-fw-text-list50-image50" */).then(c => wrapFunctional(c.default || c))
export const BlocksContentCardFwTextList50Image50White = () => import('../../components/blocks/content/CardFwTextList50Image50White.vue' /* webpackChunkName: "components/blocks-content-card-fw-text-list50-image50-white" */).then(c => wrapFunctional(c.default || c))
export const BlocksContentDefault = () => import('../../components/blocks/content/Default.vue' /* webpackChunkName: "components/blocks-content-default" */).then(c => wrapFunctional(c.default || c))
export const BlocksContentDisplay50Image50 = () => import('../../components/blocks/content/Display50Image50.vue' /* webpackChunkName: "components/blocks-content-display50-image50" */).then(c => wrapFunctional(c.default || c))
export const BlocksContentDisplayText = () => import('../../components/blocks/content/DisplayText.vue' /* webpackChunkName: "components/blocks-content-display-text" */).then(c => wrapFunctional(c.default || c))
export const BlocksContentIframeVideo = () => import('../../components/blocks/content/IframeVideo.vue' /* webpackChunkName: "components/blocks-content-iframe-video" */).then(c => wrapFunctional(c.default || c))
export const BlocksContentIframeVideo21by9 = () => import('../../components/blocks/content/IframeVideo21by9.vue' /* webpackChunkName: "components/blocks-content-iframe-video21by9" */).then(c => wrapFunctional(c.default || c))
export const BlocksContentImage30Text70 = () => import('../../components/blocks/content/Image30Text70.vue' /* webpackChunkName: "components/blocks-content-image30-text70" */).then(c => wrapFunctional(c.default || c))
export const BlocksContentImage30TextList70 = () => import('../../components/blocks/content/Image30TextList70.vue' /* webpackChunkName: "components/blocks-content-image30-text-list70" */).then(c => wrapFunctional(c.default || c))
export const BlocksContentImage50Text50 = () => import('../../components/blocks/content/Image50Text50.vue' /* webpackChunkName: "components/blocks-content-image50-text50" */).then(c => wrapFunctional(c.default || c))
export const BlocksContentImage70Text30 = () => import('../../components/blocks/content/Image70Text30.vue' /* webpackChunkName: "components/blocks-content-image70-text30" */).then(c => wrapFunctional(c.default || c))
export const BlocksContentImageCol1 = () => import('../../components/blocks/content/ImageCol1.vue' /* webpackChunkName: "components/blocks-content-image-col1" */).then(c => wrapFunctional(c.default || c))
export const BlocksContentImageCol2 = () => import('../../components/blocks/content/ImageCol2.vue' /* webpackChunkName: "components/blocks-content-image-col2" */).then(c => wrapFunctional(c.default || c))
export const BlocksContentImageCol3 = () => import('../../components/blocks/content/ImageCol3.vue' /* webpackChunkName: "components/blocks-content-image-col3" */).then(c => wrapFunctional(c.default || c))
export const BlocksContentImageCol4 = () => import('../../components/blocks/content/ImageCol4.vue' /* webpackChunkName: "components/blocks-content-image-col4" */).then(c => wrapFunctional(c.default || c))
export const BlocksContentLargePillBanners6Col = () => import('../../components/blocks/content/LargePillBanners6Col.vue' /* webpackChunkName: "components/blocks-content-large-pill-banners6-col" */).then(c => wrapFunctional(c.default || c))
export const BlocksContentPresentationFull = () => import('../../components/blocks/content/PresentationFull.vue' /* webpackChunkName: "components/blocks-content-presentation-full" */).then(c => wrapFunctional(c.default || c))
export const BlocksContentPricing2Col = () => import('../../components/blocks/content/Pricing2Col.vue' /* webpackChunkName: "components/blocks-content-pricing2-col" */).then(c => wrapFunctional(c.default || c))
export const BlocksContentPricing3Col = () => import('../../components/blocks/content/Pricing3Col.vue' /* webpackChunkName: "components/blocks-content-pricing3-col" */).then(c => wrapFunctional(c.default || c))
export const BlocksContentText30Image70 = () => import('../../components/blocks/content/Text30Image70.vue' /* webpackChunkName: "components/blocks-content-text30-image70" */).then(c => wrapFunctional(c.default || c))
export const BlocksContentText50Image50 = () => import('../../components/blocks/content/Text50Image50.vue' /* webpackChunkName: "components/blocks-content-text50-image50" */).then(c => wrapFunctional(c.default || c))
export const BlocksContentText70Image30 = () => import('../../components/blocks/content/Text70Image30.vue' /* webpackChunkName: "components/blocks-content-text70-image30" */).then(c => wrapFunctional(c.default || c))
export const BlocksContentTextList = () => import('../../components/blocks/content/TextList.vue' /* webpackChunkName: "components/blocks-content-text-list" */).then(c => wrapFunctional(c.default || c))
export const BlocksContentTextList70Image30 = () => import('../../components/blocks/content/TextList70Image30.vue' /* webpackChunkName: "components/blocks-content-text-list70-image30" */).then(c => wrapFunctional(c.default || c))
export const BlocksContentTitleCol1Text = () => import('../../components/blocks/content/TitleCol1Text.vue' /* webpackChunkName: "components/blocks-content-title-col1-text" */).then(c => wrapFunctional(c.default || c))
export const BlocksContentTitleCol2Text = () => import('../../components/blocks/content/TitleCol2Text.vue' /* webpackChunkName: "components/blocks-content-title-col2-text" */).then(c => wrapFunctional(c.default || c))
export const BlocksContentTitleCol3Text = () => import('../../components/blocks/content/TitleCol3Text.vue' /* webpackChunkName: "components/blocks-content-title-col3-text" */).then(c => wrapFunctional(c.default || c))
export const BlocksDynamicBannerLooseCol2 = () => import('../../components/blocks/dynamic/BannerLooseCol2.vue' /* webpackChunkName: "components/blocks-dynamic-banner-loose-col2" */).then(c => wrapFunctional(c.default || c))
export const BlocksDynamicBannerTightCol3 = () => import('../../components/blocks/dynamic/BannerTightCol3.vue' /* webpackChunkName: "components/blocks-dynamic-banner-tight-col3" */).then(c => wrapFunctional(c.default || c))
export const BlocksDynamicBannerUSPFigures = () => import('../../components/blocks/dynamic/BannerUSPFigures.vue' /* webpackChunkName: "components/blocks-dynamic-banner-u-s-p-figures" */).then(c => wrapFunctional(c.default || c))
export const BlocksDynamicForm = () => import('../../components/blocks/dynamic/Form.vue' /* webpackChunkName: "components/blocks-dynamic-form" */).then(c => wrapFunctional(c.default || c))
export const BlocksDynamicNewsCols3 = () => import('../../components/blocks/dynamic/NewsCols3.vue' /* webpackChunkName: "components/blocks-dynamic-news-cols3" */).then(c => wrapFunctional(c.default || c))
export const BlocksDynamicSliderBanner = () => import('../../components/blocks/dynamic/SliderBanner.vue' /* webpackChunkName: "components/blocks-dynamic-slider-banner" */).then(c => wrapFunctional(c.default || c))
export const BlocksDynamicSliderBannerFw5Col = () => import('../../components/blocks/dynamic/SliderBannerFw5Col.vue' /* webpackChunkName: "components/blocks-dynamic-slider-banner-fw5-col" */).then(c => wrapFunctional(c.default || c))
export const BlocksDynamicTestimonialBanners = () => import('../../components/blocks/dynamic/TestimonialBanners.vue' /* webpackChunkName: "components/blocks-dynamic-testimonial-banners" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
